import React, { useState, useEffect } from "react";
import axios from "axios";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import ReposView from "../components/ReposView";
import Pagination from "../components/Pagination";
import { STRAPI_TOKEN } from "../config";

function Repos() {
  const [repos, setRepos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    let mounted = true;
    axios
      .get(
        "http://strapi.danmorgan.net/api/repos?fields=name,description,language",
        {
          headers: {
            Authorization: STRAPI_TOKEN,
          },
        }
      )
      .then((response) => {
        if (mounted) {
          setRepos(
            response.data.data.map((repo) => ({
              id: repo.id,
              ...repo.attributes,
            }))
          );
        }
      });
    return () => (mounted = false);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentRepos = repos.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Code Repositories" />
          <ReposView repos={currentRepos} />
          {!(repos.length > postsPerPage) ? null : (
            <Pagination
              className="mt-50"
              itemsPerPage={postsPerPage}
              totalItems={repos.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Repos;

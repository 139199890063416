import React from "react";
import Article from "./Article";

function ArticlesView({ articles }) {
  return (
    <div className="row mt-30-reverse">
      {articles.map((article) => (
        <div className="col-lg-6 col-md-6 col-12 mt-30" key={article.id}>
          <Article data={article} />
        </div>
      ))}
    </div>
  );
}

export default ArticlesView;

import Mock from "../mock";

const database = {
  information: {
    name: "Daniel Morgan",
    aboutContent: "I am a senior full stack web developer.",
    age: null,
    phone: "",
    nationality: "American",
    language: "English, Esperanto",
    email: "dan@danmorgan.net",
    address: "Marina del Rey, CA",
    freelanceStatus: "Available",
    socialLinks: {
      facebook: null,
      twitter: null,
      pinterest: null,
      behance: null,
      linkedin: null,
      dribbble: null,
      github: "https://github.com/digdan",
    },
    brandImage:
      "https://s.gravatar.com/avatar/d67b9ab97ce37d8da5a72cac57ce1c3a?s=300",
    aboutImage: "/images/dm.jpg",
    aboutImageLg: null,
    cvfile: "/files/Daniel_Morgan_-_Senior_Software_Engineer.pdf",
  },
  services: [
    {
      title: "Web Development",
      icon: "code",
      details:
        "Can build custom solutions from ground up for many web development needs.",
    },
    {
      title: "Application Design",
      icon: "color-pallet",
      details: "Well versed at modern UI/UX and methodologies.",
    },
    {
      title: "Mobile Application",
      icon: "mobile",
      details: "Well versed in React Native application development.",
    },
  ],
  reviews: [
    {
      id: 1,
      content: "",
      author: {
        name: "Burdette Turner",
        designation: "Web Developer, Abc Company",
      },
    },
    {
      id: 2,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: "Susan Yost",
        designation: "Client",
      },
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: "Irving Feeney",
        designation: "Fiverr Client",
      },
    },
  ],
  skills: [
    {
      title: "Javascript/Typescript",
      value: 90,
    },
    {
      title: "jQuery",
      value: 60,
    },
    {
      title: "ReactJS",
      value: 80,
    },
    {
      title: "PHP",
      value: 80,
    },
    {
      title: "C++",
      value: 45,
    },
    {
      title: "Go",
      value: 50,
    },
  ],
  extraSkills: [
    "Docker",
    "AWS",
    "Git",
    "VSCode",
    "Azure",
    "ReactNative",
    "ArcGIS/OSRM/Mapbox",
    "Routing Algorithms",
    "Cradlepoint SDK/API",
    "BlockChain RPC",
    "WebPack",
    "Grunt",
    "WordPress",
    "WooCommerce",
    "PostGreSQL",
    "MarinaDB",
    "MongoDB",
    "Redux",
    "React Sagas",
    "Redis",
    "DynamoDB",
  ],
  portfolios: [
    {
      id: 1,
      title: "ClipGIF",
      subtitle:
        "A Video/Audio Mashup generator. Combined animated GIFs with MP3/YouTube Audio.",
      imageUrl: "/images/clipgif.jpg",
      largeImageUrl: null,
      url: "https://bitbucket.org/digdan/clipgif",
    },
    {
      id: 2,
      title: "CyphtJS",
      subtitle:
        "A highly portable asymmetric key-based cryptographic messaging library.",
      imageUrl: "/images/CyphtJS.jpg",
      largeImageUrl: null,
      url: "https://github.com/digdan/CyphtJS",
    },
    {
      id: 3,
      title: "Boticus",
      subtitle:
        "an artificial intelligence dedicated to generating unlimited amounts of unique inspirational quotes for endless enrichment of pointless human existence.",
      imageUrl: "/images/boticus.jpg",
      url: "https://bitbucket.org/digdan/boticus",
    },
    {
      id: 4,
      title: "AbyssFM",
      subtitle:
        "A sound analysis software suite that could seamlessly blend songs together depending on Key & Tempo. An AI DJ.",
      imageUrl: "/images/abyssfm.jpg",
      largeImageUrl: null,
      url: "https://bitbucket.org/digdan/abyssfm-worke",
    },
    {
      id: 5,
      title: "Avatar Creator",
      subtitle: "A layered and animated avatar creation website.",
      imageUrl: "/images/avatarcreator.jpg",
      largeImageUrl: null,
      url: "https://bitbucket.org/korniko/avatarcreator.org",
    },
    {
      id: 6,
      title: "Domainomatics",
      subtitle:
        "A domain profile generator. Would scrape and build extensive data on expiring domains.",
      imageUrl: "/images/domainomatics.jpg",
      largeImageUrl: null,
      url: "https://bitbucket.org/digdan/domainomatics",
    },
    {
      id: 7,
      title: "NodeDocker",
      subtitle: "A cheap and easy way to deploy node applications in a cloud",
      imageUrl: "/images/nodedocker.jpg",
      url: "https://bitbucket.org/boardentcrew/ndblank",
      largeImageUrl: null,
    },
    {
      id: 8,
      title: "Embossify",
      subtitle:
        "A website that would convert static JPEG images int oa 3D printable representation",
      imageUrl: "/images/embossify.jpg",
      largeImageUrl: null,
      url: "http://www.embossify.com",
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2021 - Present",
        position: "Senior Fullstack Engineer I",
        company: "Fashionphile.com",
        details:
          "Work on customer facing ecommerce application for luxury goods.",
        highlights: [
          "Use of NextJS and robust custom backend",
          "Full use of analytics during feature vetting",
          "Principle front-end engineer of ecommerce site that does > $500m/year",
        ],
      },
      {
        id: 1,
        year: "2019 - 2020",
        position: "Senior Fullstack Engineer",
        company: "Launched.LA",
        details:
          "Worked with small team to utilize react and typescript to maintain large subscription based ecommerce website through Shopify",
        highlights: [
          "Wrote in a pure typescript codebase",
          "Utilized many SaaS platforms",
          "Worked with AWS, Queuing systems, and analytics platform",
        ],
      },
      {
        id: 2,
        year: "2017 - 2019",
        position: "Lead Fullstack Engineer",
        company: "Saferide Health Inc.",
        details:
          "Built and maintained multifaceted react ride booking platform. Worked on mobile app and built custom routing software to optimize ride performance.",
        highlights: [
          "Built ReactJS Web Applications for custom Scheduling CMS",
          "Created live user chat using ReactJS and sockets",
          "Authored custom logistics algorithms using Google ORTools in Python",
        ],
      },
      {
        id: 3,
        year: "2017 - 2017",
        position: "Senior Backend Engineer",
        company: "Legacy",
        details: "",
        highlights: [
          "Maintained API Gateway using GraphQL and microservices",
          "Created NodeJS APIs for ReactJS Front-end",
          "Unit Testing to increase code coverage",
        ],
      },
      {
        id: 4,
        year: "2014 - 2017",
        position: "Senior Software Engineer",
        company: "Crowdgather/Tapatalk",
        details:
          "Maintained legacy forum conglomerate into its transition into the tapatalk mobile forum platform. Expansive infrastructure built with legacy PHP code.",
        highlights: [
          "Maintained and developed on legacy PHP frameworks",
          "Monitored and Managed over 40+ databases",
          "Maintained and replaced hardware in server rooms",
          "Worked with upgrading and converting many high-profile forums",
        ],
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "2018 - 2019",
        graduation: "Master of Science",
        university: "Abc University",
        details:
          "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.",
      },
      {
        id: 2,
        year: "2016 - 2018",
        graduation: "Bachelor of Science",
        university: "Abc University",
        details:
          "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.",
      },
      {
        id: 3,
        year: "2015 - 2016",
        graduation: "Higher Schoold Graduation",
        university: "Abc College",
        details:
          "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.",
      },
    ],
  },
  blogs: [
    {
      id: 1,
      title: "Markdown & Html supported blog.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/markdown-html-supported-blog.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 2,
      title: "Installing NodeJS on your device.",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/installing-nodejs-on-your-device.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 3,
      title: "UI/UX design starter with Adobe XD.",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/uiux-design-starter-with-adobe-xd.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 4,
      title: "Boost your post for increasing sales.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/boost-your-post-for-increasing-sales.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 5,
      title: "Difference between GatsbyJS & NextJS.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/difference-between-gatsbyjs-and-nextjs.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 6,
      title: "How to choose javascript framework for project.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource:
        "../../blog/how-to-choose-javascript-framework-for-project.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 7,
      title: "Web automation with python language.",
      featuredImage: "/images/blog-image-7.jpg",
      filesource: "../../blog/web-automation-with-python-language.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 8,
      title: "Time to use latest technology for creating a website.",
      featuredImage: "/images/blog-image-8.jpg",
      filesource:
        "../../blog/time-to-use-latest-technology-for-creating-a-website.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 9,
      title: "Think out of the box.",
      featuredImage: "/images/blog-image-9.jpg",
      filesource: "../../blog/think-out-of-the-box.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 10,
      title: "Trending designs in 2020.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/trending-designs-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 11,
      title: "How to get 10k instagram followers?",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/how-to-get-10k-instagram-followers.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 12,
      title: "What NodeJS can do?",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/what-nodejs-can-do.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 13,
      title: "Futures of javascript.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/future-of-javascript.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 14,
      title: "Popular javascript library in 2020.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/popular-javascript-library-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 15,
      title: "Promrammers must read books.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource: "../../blog/programmers-must-read-books.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
  ],
  contactInfo: {
    phoneNumbers: ["+1 818-926-7852", ""],
    emailAddress: ["dan@danmorgan.net", ""],
    address: "Marina del Rey, CA",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/extraSkills").reply((config) => {
  const response = database.extraSkills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});

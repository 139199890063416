import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import { STRAPI_TOKEN } from "../config";

function ArticleDetails(props) {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const articleId = props.match.params.id;

  useEffect(() => {
    axios
      .get(
        `http://strapi.danmorgan.net/api/articles?filters[id][$eq]=${articleId}`,
        {
          headers: {
            Authorization: STRAPI_TOKEN,
          },
        }
      )
      .then((result) => {
        setContent(result.data.data[0].attributes.body);
        setTitle(result.data.data[0].attributes.title);
      });
  }, []);

  return (
    <Layout>
      <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <h1>{title}</h1>
          <ReactMarkdown source={content} escapeHtml={false}></ReactMarkdown>
        </div>
      </div>
    </Layout>
  );
}

export default ArticleDetails;

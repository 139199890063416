import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import { STRAPI_TOKEN } from "../config";

function RepoDetails(props) {
  const [repoData, setRepoData] = useState("");
  const repoId = props.match.params.id;

  useEffect(() => {
    axios
      .get(`http://strapi.danmorgan.net/api/repos?filters[id][$eq]=${repoId}`, {
        headers: {
          Authorization: STRAPI_TOKEN,
        },
      })
      .then((result) => {
        setRepoData(result.data.data[0].attributes);
      });
  }, []);

  return (
    <Layout>
      <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <h1>{repoData.name || ""}</h1>
          <a target="_BLANK" href={repoData.url}>
            Link to the {repoData.name} code repository.
          </a>
          <p>Base Language: {repoData.language}</p>

          <p>{repoData.description}</p>
          <ReactMarkdown
            source={repoData.content || ""}
            escapeHtml={false}
          ></ReactMarkdown>
        </div>
      </div>
    </Layout>
  );
}

export default RepoDetails;

import React from "react";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";

function Blog(props) {
  const { id, featuredImage, title, created, filesource } = props.data;
  const getNospaceTitle = (filesource) => {
    return filesource;
  };
  return (
    <div className="mi-blog">
      <div className="mi-blog-image">
        {/* <Link to={`blogs/blog-details/${id}/${getNospaceTitle(filesource)}`}>
          <ProgressiveImage
            src={featuredImage}
            placeholder="/images/blog-image-placeholder.png"
          >
            {(src) => <img src={src} alt={title} />}
          </ProgressiveImage>
        </Link>
        <div className="mi-blog-date">
          <span className="date">{created}</span>
        </div> */}
      </div>
      <Link to={`blogs/${id}/${getNospaceTitle(filesource)}`}>
        <div className="mi-blog-content">
          <h5>
            <div className="date">{created}</div>
            {title}
          </h5>
        </div>
      </Link>
    </div>
  );
}

export default Blog;

import React from "react";
import Repo from "./Repo";

function ReposView({ repos }) {
  return (
    <div className="row mt-30-reverse">
      {repos.map((repo) => (
        <div className="col-lg-6 col-md-6 col-12 mt-30" key={repo.id}>
          <Repo data={repo} />
        </div>
      ))}
    </div>
  );
}

export default ReposView;

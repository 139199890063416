import React from "react";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";

function slug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeeiiiiooooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

function Repo(props) {
  const { id, name, description, language } = props.data;
  console.log("p", props.data);
  return (
    <div className="mi-blog">
      <div className="mi-blog-image">
        {/* <Link to={`blogs/blog-details/${id}/${getNospaceTitle(filesource)}`}>
          <ProgressiveImage
            src={featuredImage}
            placeholder="/images/blog-image-placeholder.png"
          >
            {(src) => <img src={src} alt={title} />}
          </ProgressiveImage>
        </Link>
        <div className="mi-blog-date">
          <span className="date">{created}</span>
        </div> */}
      </div>
      <Link to={`repos/${id}/${slug(name)}`}>
        <div className="mi-blog-content">
          <span className="language">{language}</span>
          <h5>{name}</h5>
          <p>{description}</p>
        </div>
      </Link>
    </div>
  );
}

export default Repo;

import axios from "axios";
import Disqus from "disqus-react";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import { STRAPI_TOKEN } from "../config";

function BlogDetails(props) {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [created, setCreated] = useState("");
  const blogId = props.match.params.id;

  useEffect(() => {
    axios
      .get(
        `http://strapi.danmorgan.net/api/blog-posts?filters[id][$eq]=${blogId}`,
        {
          headers: {
            Authorization: STRAPI_TOKEN,
          },
        }
      )
      .then((result) => {
        setContent(result.data.data[0].attributes.body);
        setTitle(result.data.data[0].attributes.title);
        setCreated(result.data.data[0].attributes.created);
      });
  }, []);

  return (
    <Layout>
      <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <h1>{title}</h1>
          <h3>{created}</h3>
          <ReactMarkdown source={content} escapeHtml={false}></ReactMarkdown>
        </div>
      </div>
    </Layout>
  );
}

export default BlogDetails;

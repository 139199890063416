import React, { useState, useEffect } from "react";
import axios from "axios";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import ArticlesView from "../components/ArticlesView";
import Pagination from "../components/Pagination";
import { STRAPI_TOKEN } from "../config";

function Articles() {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    let mounted = true;
    axios
      .get("http://strapi.danmorgan.net/api/articles?fields=title,summary", {
        headers: {
          Authorization: STRAPI_TOKEN,
        },
      })
      .then((response) => {
        if (mounted) {
          setArticles(
            response.data.data.map((article) => ({
              id: article.id,
              ...article.attributes,
            }))
          );
        }
      });
    return () => (mounted = false);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentArticles = articles.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Articles" />
          <ArticlesView articles={currentArticles} />
          {!(articles.length > postsPerPage) ? null : (
            <Pagination
              className="mt-50"
              itemsPerPage={postsPerPage}
              totalItems={articles.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Articles;
